import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { postOrThrowWithAuth, useGetMe } from "./queries";

import IndexPage from "./pages/IndexPage";
import ChatPage from "./pages/chat/ChatPage";
import PlanPage from "./pages/plan/PlanPage";
import NutritionPage from "./pages/nutrition/NutritionPage";
import ProfilePage from "./pages/profile/ProfilePage";
import SubscriptionStartedPage from "./pages/subscription-started/SubscriptionStartedPage";
import OnSignupPage from "./pages/on-signup/OnSignupPage";

import "./index.scss";
import NotificationsUnsubscribedPage from "./pages/notifications-unsubscribed/NotificationsUnsubsribed";
import DeleteMePage from "./pages/delete-me/DeleteMePage";
import { config } from "./Constants";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <IndexPage />,
  },
  {
    path: "/chat",
    element: <ChatPage />,
  },
  {
    path: "/plan",
    element: <PlanPage />,
  },
  {
    path: "/food",
    element: <NutritionPage />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "/subscription-started",
    element: <SubscriptionStartedPage />,
  },
  {
    path: "/on-signup",
    element: <OnSignupPage />,
  },
  {
    path: "/unsubscribed",
    element: <NotificationsUnsubscribedPage />,
  },
  {
    path: "/delete-me",
    element: <DeleteMePage />,
  },
]);

if (process.env.REACT_APP_PUBLIC_POSTHOG_KEY) {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  });
} else {
  console.warn("PostHog key not found, skipping initialization");
}

const NotificationComponent = () => {
  const { data: me, isLoading } = useGetMe();

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;
    if (isLoading || !me) return;

    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === "granted") {
        PushNotifications.register().then(() => {
          console.log("Push notifications registered successfully.");
        });
      } else {
        console.warn("Push notifications permission denied.");
      }
    });

    PushNotifications.addListener("registration", (token) => {
      console.log("FCM registration token:", token.value);
      postOrThrowWithAuth(new URL(`${config.API_URL}/register-fcm-token`), {
        fcm_token: token.value,
      }).then((response) => {
        if (response.ok) {
          console.log("FCM token sent to Flask server successfully:", response);
        } else {
          console.error("Error sending FCM token to Flask server:", response);
        }
      });
    });
  }, [me, isLoading]);

  return null;
};

const root = createRoot(document.getElementById("root")!);

root.render(
  <QueryClientProvider client={queryClient}>
    <PostHogProvider client={posthog}>
      <RouterProvider router={router} />
    </PostHogProvider>
    <NotificationComponent />
    <ReactQueryDevtools initialIsOpen={false} buttonPosition={"top-right"} />
  </QueryClientProvider>,
);
